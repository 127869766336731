import type {
  Investor,
  Deal,
  Recaptcha,
  InvestorOtpAccessLink,
  PersonalInfo,
} from 'Utility/investorFlow/entity';
import {getUTMParameters} from 'Utility/api';
const whitelistedDomain = document.location.href;

export type PostInvestorParams = PersonalInfo & {
  baseUrl: URL;
  dealId: string;
  recaptcha_token: string;
  recaptcha_invisible: boolean;
  tags: string[];
  invitation_uid: string;
};

export type PatchUpdateInvestorParams = {
  baseUrl: URL;
  dealId: string;
  investment_amount?: number;
  token: string;
  referrer_email?: string;
  incentive_code?: string;
};

const response = async (r: Response) => {
  if (r && r.ok) {
    return r.json();
  } else {
    const json = await r.json();
    throw new Error(json['error'] || 'Bad Request');
  }
};

const error = async (e: Error) => {
  throw new Error(e.message || 'Something went wrong');
};

export const apiGetDeal = async (
  baseUrl: URL,
  dealId: string
): Promise<Deal> => {
  const api = baseUrl ? `${baseUrl}/deals/${dealId}` : `/api/deals/${dealId}`;
  return fetch(api, {
    method: 'GET',
    headers: {
      accept: 'application/vnd.dealmaker-embed_v1+json',
      'X-DEALMAKER-DOMAIN': whitelistedDomain,
    },
  })
    .then(response)
    .catch(error);
};

export const apiGetOtpAccessLink = async (
  baseUrl: URL,
  dealId: string,
  investorId: string,
  recaptcha_token: string,
  recaptcha_invisible: boolean
): Promise<InvestorOtpAccessLink> => {
  const api = baseUrl
    ? `${baseUrl}/deals/${dealId}/investors/${investorId}/otp_access_link`
    : `/api/deals/${dealId}/investors/${investorId}/otp_access_link`;

  return fetch(api, {
    method: 'GET',
    headers: {
      accept: 'application/vnd.dealmaker-embed_v1+json',
      'X-DEALMAKER-DOMAIN': whitelistedDomain,
      'g-recaptcha-response': recaptcha_token,
      'g-recaptcha-invisible': recaptcha_invisible?.toString(),
    },
  })
    .then(response)
    .catch(error);
};

export const apiPostInvestor = async (
  params: PostInvestorParams
): Promise<Investor> => {
  const {
    name,
    email,
    tags,
    phone: phone_number,
    investment_amount,
    recaptcha_token,
    recaptcha_invisible,
    baseUrl,
    dealId,
    invitation_uid,
  } = params;

  const api = baseUrl
    ? `${baseUrl}/deals/${dealId}/investors`
    : `/api/deals/${dealId}/investors`;
  const [firstName = '', lastName = ''] = name.split(' ');

  // Get UTM parameters as headers
  const utmHeaders = getUTMParameters();

  const payload: RequestInit = {
    method: 'POST',
    headers: {
      accept: 'application/vnd.dealmaker-embed_v1+json',
      'X-DEALMAKER-DOMAIN': whitelistedDomain,
      'g-recaptcha-response': recaptcha_token,
      'g-recaptcha-invisible': recaptcha_invisible.toString(),
      'Content-Type': 'application/json',
      ...utmHeaders, // Merge UTM headers
    },
    body: JSON.stringify({
      first_name: firstName,
      last_name: lastName,
      name,
      email,
      tags,
      phone_number,
      investment_amount,
      invitation_uid,
    }),
  };

  return fetch(api, payload).then(response).catch(error);
};

export const apiPatchUpdateInvestor = async (
  params: PatchUpdateInvestorParams
): Promise<Investor> => {
  const api = params.baseUrl
    ? `${params.baseUrl}/deals/${params.dealId}/investors`
    : `/api/deals/${params.dealId}/investors`;

  const payload: RequestInit = {
    method: 'PATCH',
    headers: {
      accept: 'application/vnd.dealmaker-embed_v1+json',
      'X-DEALMAKER-DOMAIN': whitelistedDomain,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token: params.token,
      investment_amount: params.investment_amount,
      referrer_email: params.referrer_email,
      incentive_code: params.incentive_code,
    }),
  };

  return fetch(api, payload).then(response).catch(error);
};

export const apiGetSiteKey = async (baseUrl: URL): Promise<Recaptcha> => {
  const api = baseUrl
    ? `${baseUrl}/recaptcha/sitekey`
    : '/api/recaptcha/sitekey';
  return fetch(api, {
    method: 'GET',
    headers: {
      accept: 'application/vnd.dealmaker-embed_v1+json',
    },
  })
    .then(response)
    .catch(error);
};

export const apiVerifyScore = async (baseUrl: URL, token: string) => {
  const api = baseUrl
    ? `${baseUrl}/recaptcha/verifyscore`
    : '/api/recaptcha/verifyscore';
  return fetch(api, {
    method: 'POST',
    headers: {
      accept: 'application/vnd.dealmaker-embed_v1+json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      response_token: token,
    }),
  })
    .then(response)
    .catch(error);
};
